import { useEffect, useState, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { IUser, useDomain, useIdentitySiteUrls, useUser } from '@netfront/gelada-identity-library';
import { Dropdown, IDropdownNavigationItem, LogoAvatar, Spinner, UserNavigation, UserNavigationItemProps } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { CachingEntitiesContext, PermissionContext, UserContext } from '../../contexts';
import { useHasPartner, useStartStripeSession, useUserIsAdmin } from '../../hooks';

const UserAccountMenu = () => {

  const { cmsBaseUrl } = useContext(CachingEntitiesContext);

  const {
    query: { projectId: queryProjectId, organisationKey: queryOrganisationKey },
  } = useRouter();

  const { hasPermission } = useContext(PermissionContext);
  const { profileImageUrl, fullName, email } = useContext(UserContext);


  const { getUser } = useUser();

  const { isDomainReady, getDomain } = useDomain();
  const { getProfileUrl, getLogoutUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [projectId, setProjectId] = useState<string>('');
  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [administrationUrl, setAdministrationUrl] = useState<string>();
  const [partnerUrl, setPartnerUrl] = useState<string>();
  const [profileUrl, setProfileUrl] = useState<string>();
  const [footerConfig, setFooterConfig] = useState<IDropdownNavigationItem>();
  const [navigationList, setNavigationList] = useState<UserNavigationItemProps[]>([]);
  const [loggedUser, setLoggedUser] = useState<IUser>();
  const [hasPartner, setHasPartner] = useState<boolean>(false);

  const hasEditPermission = hasPermission(String(projectId), 'EDIT');
  const { isAdmin } = useUserIsAdmin();
  const { handleStartStripeSession, isLoading: isStartStripeSessionLoading = false } = useStartStripeSession({
    onCompleted: ({ startStripeSession }) => {
      const { url } = startStripeSession;

      window.location.href = url;
    },
    onError: (error: ApolloError) => console.error(error),
  });

  const { handleHasPartner } = useHasPartner({
    onCompleted(data) {
      const { hasPartner: hasAnyPartner } = data;
      setHasPartner(hasAnyPartner);
    },
  });

  const handleCreateStripeSession = () => {
    void handleStartStripeSession({
      id: String(projectId),
      product: 'QUICKCODES',
      returnUrl: `${String(process.env.REACT_APP_TAMARIN_SERVER_URL)}/dashboard`,
    });
  };

  useEffect(() => {
    void handleHasPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setLoggedUser(getUser());
    setLogoutUrl(getLogoutUrl());
    setProfileUrl(getProfileUrl());
    setAdministrationUrl(`https://partners.${getDomain()}/management/springboard`);
    setPartnerUrl(`https://partners.${getDomain()}/dashboard`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!(cmsBaseUrl && loggedUser && profileUrl && organisationKey && projectId)) {
      return;
    }

    const dropdownNavigationItems: UserNavigationItemProps[] = [
      {
        header: {
          title: String(fullName),
          name: String(email),
          iconId: 'id_enter_icon',
          href: String(profileUrl),
          linkText: 'View profile'
        },
        options: [
          {
            iconId: 'id_plans_icon',
            onClick: handleCreateStripeSession,
            label: 'Billing',
            isHidden: !hasEditPermission && !projectId
          },
          {
            iconId: 'id_plans_icon',
            href: `/dashboard/${String(organisationKey)}/${String(projectId)}/payment`,
            label: 'Manage plan',
            isHidden: !projectId
          },
          {
            iconId: 'id_help_docs_icon',
            href: `/docs`,
            label: 'Help docs',
          },
          {
            iconId: 'id_calendar_icon',
            href: `https://calendly.com/greg_stephenson`,
            label: 'Book demo',
            target: '_blank',
          },
          {
            iconId: 'id_partners_icon',
            href: partnerUrl,
            label: 'Partner',
            isHidden: !hasPartner,
          },
          {
            iconId: 'id_users_icon',
            href: administrationUrl,
            label: 'Administration',
            isHidden: !isAdmin,
          },
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);

    setFooterConfig({
      label: 'Logout',
      href: logoutUrl,
      iconId: 'id_logout_icon'
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsBaseUrl, organisationKey, projectId, profileUrl, hasPartner, partnerUrl, administrationUrl]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setOrganisationKey(queryOrganisationKey as string);
  }, [queryOrganisationKey]);

  const isLoading = isStartStripeSessionLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <div className="c-user-account-menu">
        {loggedUser && (
          <Dropdown
            dropdownId="profile-dropdown"
            isDisplayContent={isSearchContentVisible}
            trigger={<LogoAvatar avatarImageSrc={decodeURIComponent(String(profileImageUrl))} size="small" title={String(fullName)} />}
            onDisplayContent={setSearchIsContentVisible}
          >
            <UserNavigation dropdownNavigationItems={navigationList} footerConfig={footerConfig} />
          </Dropdown>
        )}
      </div>
    </>
  );
};

export { UserAccountMenu };
