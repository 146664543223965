import { START_STRIPE_SESSION_QUERY } from './useStartStripeSession.graphql';
import {
  IStartStripeSessionQueryGraphQLResponse,
  IStartStripeSessionQueryVariables,
  IHandleStartStripeSessionParams,
  IUseStartStripeSession,
  IUseStartStripeSessionOptions,
} from './useStartStripeSession.interfaces';

import { useMuriquiLazyQuery } from '../../apollo';

const useStartStripeSession = (options?: IUseStartStripeSessionOptions): IUseStartStripeSession => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseStartStripeSessionOptions);

  const [executeStartStripeSession, { loading: isLoading }] = useMuriquiLazyQuery<
    IStartStripeSessionQueryGraphQLResponse,
    IStartStripeSessionQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projects: { startStripeSession },
        } = data;

        onCompleted({
          startStripeSession,
        });
      },
      onError,
    },
    query: query ?? START_STRIPE_SESSION_QUERY,
    token,
  });

  const handleStartStripeSession = async ({ id, product, returnUrl }: IHandleStartStripeSessionParams) => {
    await executeStartStripeSession({
      variables: {
        id,
        product,
        returnUrl,
      },
    });
  };

  return {
    handleStartStripeSession,
    isLoading,
  };
};

export { useStartStripeSession };
