import { IMenuItem } from '../../interfaces';

const PUBLIC_MENU_ITEMS: IMenuItem[] = [
  {
    href: '/pricing',
    label: 'Pricing',
    category: 'Product',
  },
  {
    href: '/blog',
    label: 'Blog',
    category: 'QuickCodes',
  },
  {
    href: '/contact',
    label: 'Contact',
    category: 'QuickCodes',
  },
];

export { PUBLIC_MENU_ITEMS };
