import { gql } from '@apollo/client';

const START_STRIPE_SESSION_QUERY = gql`
  query ($id: String!, $product: EProduct!, $returnUrl: String!) {
    projects {
      startStripeSession(id: $id, product: $product, returnUrl: $returnUrl) {
        url
      }
    }
  }
`;

export { START_STRIPE_SESSION_QUERY };
