import { useEffect, useState, useContext } from 'react';

import { IGeladaAccessTokenPayload, useCookie, useJwtDecoder } from '@netfront/common-library';
import { useIdentitySiteUrls, useDomain } from '@netfront/gelada-identity-library';
import { Dropdown, Icon, OrganisationNavigation, OrganisationNavigationItemProps } from '@netfront/ui-library';
import { useRouter } from 'next/router';


import { CachingEntitiesContext } from '../../contexts';

const OrganisationMenu = () => {
  const { query } = useRouter();

  const { projectId: queryProjectId, organisationKey: queryOrganisationKey } = query;

  const { isDomainReady } = useDomain();

  const { getDecodedJwt } = useJwtDecoder();
  const { getAccessTokenCookie } = useCookie();

  const { project, organisation, cmsBaseUrl } = useContext(CachingEntitiesContext);

  const { getBaseUrl: identityBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [projectId, setProjectId] = useState<string>('');
  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isCustomBuild, setIsCustomBuild] = useState<boolean>(false);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [navigationList, setNavigationList] = useState<OrganisationNavigationItemProps[]>([]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(identityBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!(cmsBaseUrl && organisation && project && projectId && organisationKey)) {
      return;
    }

    const dropdownNavigationItems: OrganisationNavigationItemProps[] = [
      {
        isHidden: Boolean(project),
        header: {
          title: `Current account`,
          name: String(organisation.name),
          iconId: 'id_switch_icon',
          href: `${identityUrl}/dashboard`,
          isHidden: isCustomBuild
        },
        options: [
          {
            iconId: 'id_team_icon',
            label: 'Team',
            href: `${identityUrl}/account/${String(organisationKey)}/team`,
          },
        ],
      },
      {
        isHidden: !project,
        header: {
          title: `Current space`,
          name: project.name,
          isHidden: isCustomBuild,
          iconId: 'id_switch_icon',
          href: `${identityUrl}/dashboard`,
        },
        options: [
          {
            iconId: 'id_users_icon',
            label: 'Team',
            isHidden: !project,
            href: `${identityUrl}/project/${String(projectId)}/user`
          },
        ],
      },
    ];


    setNavigationList(dropdownNavigationItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsBaseUrl, identityUrl, projectId, organisationKey]);

  useEffect(() => {
    const accessToken = getAccessTokenCookie();

    if (!accessToken) {
      return;
    }

    const decoded = getDecodedJwt(accessToken) as IGeladaAccessTokenPayload;
    const claim = decoded.custom_build;

    setIsCustomBuild(Boolean(String(claim).toLowerCase() === 'true'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setOrganisationKey(queryOrganisationKey as string);
  }, [queryOrganisationKey]);


  return (
    <div className="c-organisation-menu">
      <Dropdown
        dropdownId="organisation-dropdown"
        isDisplayContent={isSearchContentVisible}
        trigger={<Icon className="c-app-header__item" id="id_organisation_icon" />}
        onDisplayContent={setSearchIsContentVisible}
      >
        <OrganisationNavigation
          dropdownNavigationItems={navigationList}
        />
      </Dropdown>
    </div>
  );
};

export { OrganisationMenu };
