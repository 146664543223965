import { ButtonTheme } from './LinkButton.types';

const BUTTON_CLASSES: Record<ButtonTheme, string> = Object.freeze({
  red: 'bg-primary h-12 color-white px-6 text-base rounded-sm inline-flex justify-center items-center weight-600 hover:bg-red-300',
  white:
    'bg-white h-12 color-black px-6 text-base rounded-sm inline-flex justify-center items-center weight-600 hover:bg-purple-700 hover:color-white',
  ['subtle-link']: 'bg-transparent p-0 border-none text-base hover:bg-transparent focus:bg-transparent',
  yellow: 'bg-yellow-400 h-12 px-6 color-black text-base rounded-sm inline-flex justify-center items-center weight-600 hover:bg-yellow-400',
});

export { BUTTON_CLASSES };
